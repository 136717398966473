import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container my-3 2xl:my-5 py-3 2xl:py-1 px-10" }
const _hoisted_2 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-28" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "el-icon el-icon--upload" }
const _hoisted_8 = { class: "el-icon el-icon--upload" }
const _hoisted_9 = { class: "flex justify-end items-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3 mt-10" }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_Field = _resolveComponent("Field")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_BaseSvgIcon = _resolveComponent("BaseSvgIcon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      as: "el-form",
      "validation-schema": _ctx.schema,
      onSubmit: _ctx.onSubmit,
      "label-position": "top",
      class: "relative"
    }, {
      default: _withCtx(({ isSubmitting }) => [
        _createElementVNode("div", {
          class: _normalizeClass(['px-10', isSubmitting && 'isSubmitting-rounded'])
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-full 2xl:py-5 py-3 flex justify-between items-center mb-2" }, [
            _createElementVNode("h1", {
              class: "text-2xl 2xl:text-4xl font-bold text-white",
              "data-aos": "fade-right"
            }, " Create New Customer ")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Field, { name: "customerName" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Name*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode(_component_Field, { name: "country" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Country*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode(_component_Field, { name: "packageId" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Level of contract*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps({ class: "w-full" }, field, {
                        "model-value": value,
                        placeholder: ""
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packageData, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.Id,
                              label: item.Name,
                              value: item.Id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode(_component_Field, { name: "createdByUserIds" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Manage by (Add New User here)*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps({ class: "w-full" }, field, {
                        "model-value": value,
                        multiple: "",
                        placeholder: ""
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userData, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.Id,
                              label: item.Name,
                              value: item.Id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Field, { name: "imageUrl" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    error: errorMessage,
                    label: "Upload Image*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_upload, {
                        class: "file-uploader",
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        drag: "",
                        "show-file-list": false,
                        "on-success": (res, file) => _ctx.handleUploadImageSuccess(res, file, field),
                        "before-upload": _ctx.beforeUploadImage
                      }, {
                        default: _withCtx(() => [
                          value
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: value + '',
                                class: ""
                              }, null, 8, _hoisted_5))
                            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("i", _hoisted_7, [
                                  _createVNode(_component_BaseSvgIcon, {
                                    class: "inline-block group-hover:text-indino-primary text-white w-6 h-6",
                                    name: "picture"
                                  })
                                ]),
                                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "el-upload__text" }, [
                                  _createTextVNode(" Drop file here or "),
                                  _createElementVNode("em", null, "click to upload")
                                ], -1))
                              ]))
                        ]),
                        _: 2
                      }, 1032, ["on-success", "before-upload"]),
                      _createVNode(_component_el_input, _mergeProps({
                        class: "hidden",
                        hidden: ""
                      }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode(_component_Field, { name: "fileUrl" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    error: errorMessage,
                    label: "Upload contract (optional)"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_upload, {
                        class: "file-uploader",
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        drag: "",
                        "show-file-list": false,
                        "on-success": (res, file) => _ctx.handleUploadFileSuccess(res, file, field),
                        "before-upload": _ctx.beforeUploadFile
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("i", _hoisted_8, [
                            _createVNode(_component_BaseSvgIcon, {
                              class: "inline-block group-hover:text-indino-primary text-white w-6 h-6",
                              name: "folder"
                            })
                          ]),
                          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "el-upload__text" }, [
                            _createTextVNode(" Drop file here or "),
                            _createElementVNode("em", null, "click to upload")
                          ], -1))
                        ]),
                        _: 2
                      }, 1032, ["on-success", "before-upload"]),
                      _createVNode(_component_el_input, _mergeProps({
                        class: "hidden",
                        hidden: ""
                      }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[2] || (_cache[2] = _createElementVNode("button", {
              type: "reset",
              class: "btn btn--gray"
            }, "Cancel", -1)),
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn--red-primary",
              disabled: isSubmitting
            }, " Create ", 8, _hoisted_10)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit"])
  ]))
}